<template>
  <el-row>
    <el-col :span="24" v-for="s in clouddeviceslist" :key="s.deviceid">
      <h2>{{s.devicename}}({{s.devicecode}})</h2>
      <el-col :xs="24" :sm="12" :md="8" :lg="6" v-for="o in s.sensors" :key="o.sensorid">
        <el-card class="cardmargin">
          <el-tooltip effect="light" placement="right-start">
            <div slot="content" class="cardright">
              <span>电压：{{o.vcc || "- -"}} V</span><br>
              <div>信号：{{o.dbm || "- -"}} dBm</div>
            </div>
            <div class="cardleft">
              <span class="label">{{o.sensorcode}}#</span>
              <span>{{o.warehousename}}</span>
            </div>
          </el-tooltip>
          <div class="cardright">
            <div>
              <span v-if="Boolean(o.flag&0b0011)" style="color:red">温度：{{o.temperature}}℃</span>
              <span v-else>温度：{{o.temperature}}℃</span>
            </div>
            <div v-if="o.sensorkind === 0"> 
              <span v-if="Boolean(o.flag&0b1100)" style="color:red">湿度：{{o.humidity}}%</span>
              <span v-else>湿度：{{o.humidity}}%</span>
            </div>
            <div class="time">{{ '更新时间：'+o.updatetime }}</div>
          </div>
        </el-card>
      </el-col>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      warehousetype: 2,
      clouddeviceslist: [],
      socket: null
    };
  },
  computed: {
    ...mapGetters(["customerid"])
  },
  methods: {
    destroySocket: function() {
      this.socket.close();
    }
  },
  destroyed() {
    let that = this;
    setTimeout(function() {
      that.destroySocket();
    }, 5000);
    // 五秒后断开websocket
  },
  mounted() {
    let that = this;

    that.axios
      .post("/detection/getcloudwarehouses", {
        customerid: that.customerid,
        warehousetype: this.warehousetype
      })
      .then(res => {
        that.clouddeviceslist = res.data.values.deviceslist;
      });
    that.socket = new WebSocket(`wss://${window.location.hostname.replace(".cn","")}:30000`);
    that.socket.onopen = event => {
      // 通知wsServer当前浏览器的客户ID
      that.socket.send(that.customerid);

      that.socket.onmessage = msg => {
        if (msg.data.indexOf(",") < 0) {
          return;
        }
        console.log(msg.data);
        let {
          0: customerid,
          1: devicecode,
          2: sensorcode,
          3: temperature,
          4: humidity,
          5: flag,
          6: vcc,
          7: dbm
        } = msg.data.split(",");

        that.clouddeviceslist.map(deviceInfo => {
          if (deviceInfo.devicecode == devicecode) {
            deviceInfo.sensors.map(sensorInfo => {
              if (sensorInfo.sensorcode == sensorcode) {
                sensorInfo.temperature = temperature;
                sensorInfo.humidity = humidity;
                sensorInfo.flag = flag;
                sensorInfo.updatetime = new Date().format("mm-dd HH:MM:ss");
                sensorInfo.vcc = vcc;
                sensorInfo.dbm = dbm;
              }
            });
          }
        });
      };
      that.socket.onclose = closeEvent => {
        // that.$message({
        // 	message: 'websocket断开',
        // 	type: 'warning'
        // });
      };
      that.socket.onerror = errorEvent => {
        // that.$message({
        // 	message: 'websocket出错',
        // 	type: 'warning'
        // });
      };
    };
  }
};
</script>

<style scoped>
.time {
  font-size: 13px;
  color: #999;
}

.spanright {
  float: right;
}

.cardleft {
  width: 60px;
  float: left;
  min-height:86px;
}

.cardright {
  line-height: 22px;
}

.label {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: #10c469;
  margin-right: 20px;
  float: left;
  margin-bottom: 4px;
}

.cardmargin {
  margin: 0.5rem 1rem;
}

h2 {
  line-height: 45px;
  font-size: 18px;
  padding-left: 15px;
  width: 97%;
  margin: 12px auto;
  background: #f1f2f7;
  /* font-weight: normal; */
}

h2 span {
  float: right;
  padding-right: 15px;
}
</style>